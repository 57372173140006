import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
declare var Stripe;
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormArray, Form } from '@angular/forms';


@Component({
  selector: 'app-boxdetails-edit',
  templateUrl: './boxdetails-edit.component.html',
  styleUrls: ['./boxdetails-edit.component.scss']
})
export class BoxdetailsEditComponent implements OnInit {
  id: number;
  sub: any;
  packageInfo: any;
  deliveryDate: Date;
  packageForm: FormGroup;
  
  constructor(private route: ActivatedRoute,private fb: FormBuilder) { 
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.packageInfo = JSON.parse(params['address']);
      this.deliveryDate = new Date(params['ddate'])
      console.log(this.packageInfo)
    });
    console.log(this.packageInfo)
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
   });
   this.packageForm = this.fb.group({
      addressLine1:['',[Validators.required]],
      addressLine2: ['',[Validators.required]],
      city: ['',[Validators.required]],
      state: ['',[Validators.required]],
      zip: ['',[Validators.required]],
      phone: ['',[Validators.required]],
      name: ['',[Validators.required]],
      deliveryDate:['',[Validators.required]],
      note: ['',[Validators.required]]
    })
    this.packageForm.controls['addressLine1'].setValue(this.packageInfo.addressLine1)
    this.packageForm.controls['city'].setValue(this.packageInfo.city)
    this.packageForm.controls['state'].setValue(this.packageInfo.state)
    this.packageForm.controls['zip'].setValue(this.packageInfo.zip)
    this.packageForm.controls['name'].setValue(this.packageInfo.name)
    this.packageForm.controls['note'].setValue(this.packageInfo.note)
    this.packageForm.controls['deliveryDate'].setValue(this.deliveryDate)
    
    if(this.packageInfo.addressLine2){
      this.packageForm.controls['addressLine2'].setValue(this.packageInfo.addressLine2)
    }
    if(this.packageInfo.phone){
      this.packageForm.controls['phone'].setValue(this.packageInfo.phone)
    }
   //console.log(history.state)
   //this.packageInfo = window.history.state.data;
   console.log(this.packageInfo)
  }

}
