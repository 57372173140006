import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, AfterViewChecked, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormArray, Form } from '@angular/forms';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
declare var Stripe;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit,AfterViewChecked {
  
  stripe;
  card;
  cardErrors;
  formSteps = [0, 1, 2, 3,4];
  children =[];
  childIndex=0;
  cardValidation;
  cardMount = false;
  selectChild: Number;
  currentStep = this.formSteps[0];
  email: FormControl;
  name: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  childForm: FormGroup;
  childrenForm: FormArray;
  childBirthDay: FormControl;
  childName: FormControl;
  childAgeYear: FormControl;
  childAgeMonth: FormControl;
  childGender: FormControl;
  childStyle: FormControl;
  childHeight: FormControl;
  childWeight: FormControl;
  shippingName: FormControl;
  shippingAddressLine1: FormControl;
  shippingAddressLine2: FormControl;
  //shippingAddressLine2Check: boolean;
  shippingAddressLine2Check = true;
  shippingState: FormControl;
  shippingCity: FormControl;
  shippingZip: FormControl;
  shippingNote: FormControl;
  shippingPhone: FormControl;
  shippingDate: FormControl;
  shippingMonth: String;
  shippingDayEnding: String;
  convertedShippingDate: Date;
  paymentName: FormControl;
  paymentCCNumber: FormControl;
  paymentExpDate: FormControl;
  paymentZip: FormControl;
  paymentCVC: FormControl;
  subPrice = 65.00;
  childCount: number;
  todaysDate = new Date();
  shippingMinDate = new Date();
  shippingMaxDate = new Date();
  token: String;


  //@ViewChild('cardElement',{static:true}) cardElement: ElementRef;
  @ViewChildren('shownDiv') divs: QueryList<ElementRef>;

  ngAfterViewChecked(){
    let shown = this.divs.find(div => !!div);
    //console.log("DIV shown is "+ shown);
    if (shown) {
     // this.mountCard();
    }
  }

  calculateCost() {
    return this.childrenForm.controls.length * 75
  }

  async increaseStep() {
    if(this.currentStep == 3){
      //this.mountCard()
      //console.log('need to mount')
      var status = await this.userSubscribe();
      if (status == 'error'){
        return
      }
    }
    this.currentStep = this.currentStep + 1;
    //console.log('testing')
  }
  decreaseStep() {
    this.currentStep = this.currentStep - 1;
    //console.log('testing')
  }

  async tokenizeCard(){
    var tokenInfo = await this.stripe.createSource(this.card);
    //console.log(tokenInfo)
  }
  validateData(step: number) {
    switch (step) {
      case 1:
        //console.log('child case')
         //console.log(this.childrenForm);
        if (this.childrenForm.status == 'VALID') {
          //console.log('succed!')
          this.childCount = 1;
          return false;
        }
        else {
          //console.log('fail!')
          return true;
        }
        break;
      case 2:
        if (this.shippingAddressLine1.valid && this.shippingCity.valid && this.shippingDate.valid && this.shippingName.valid && this.shippingPhone.valid && this.shippingState.valid && this.shippingZip.valid) {
          this.convertedShippingDate = new Date(this.shippingDate.value);
          //console.log(this.shippingAddressLine1)
          var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          this.shippingMonth = months[this.convertedShippingDate.getMonth()]
          if (this.convertedShippingDate.getDate() < 10 || this.convertedShippingDate.getDate() > 19) {
            switch (this.convertedShippingDate.getDate() % 10) {
              case 1:
                this.shippingDayEnding = 'st';
                break;
              case 2:
                this.shippingDayEnding = 'nd';
                break;
              case 3:
                this.shippingDayEnding = 'rd';
                break;
              default:
                this.shippingDayEnding = 'th'
                break;
            }
          }
          else{
            this.shippingDayEnding = 'th'
          }

          if (this.shippingAddressLine2.value.length >= 1) {
            this.shippingAddressLine2Check = true;
          }
          else {
            this.shippingAddressLine2Check = false;
          }
          return false;
        }
        else {
          //console.log(false)
          return true;
        }
        break;
        case 3:
          //console.log(this.card)
          return !this.cardValidation;
    }

  }
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let confirm = control.value;
      if (typeof this.password.value !== 'undefined') {
        if (confirm === this.password.value) {
          return null
        }
        return {
          error: "not matching"
        }
      }
      else {
        return {
          error: "no value"
        }
      }
    }
  }
  addChildControl(){
    let arrayLen= this.childrenForm.length;
    let newChild: FormGroup= this.fb.group({
      childName:['',[Validators.required]],
        childBirthday: ['',[Validators.required]],
        childGender: ['',[Validators.required]],
        childHeight: ['',[Validators.required]],
        childWeight: ['',[Validators.required]],
        childStyle: ['',[Validators.required]]
    })
    this.childrenForm.insert(arrayLen,newChild);
  }
  removeChildControl(i){
    //console.log('removing')
    this.childrenForm.removeAt(i);
    if(this.selectChild == i){
      this.selectChild=0;
    }
  }

  constructor(private fb: FormBuilder, private data: DataService, public auth: AuthService) { }



  ngOnInit() {

    this.checkSubscription();
    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.name = new FormControl('', [Validators.required, Validators.minLength(3)]);
    this.password = new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]);
    this.confirmPassword = new FormControl('', [Validators.required, this.passwordValidator(), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]);
    this.childrenForm= this.fb.array([
      this.fb.group({
        childName:['',[Validators.required]],
        childBirthday: ['',[Validators.required]],
        childGender: ['',[Validators.required]],
        childHeight: ['',[Validators.required]],
        childWeight: ['',[Validators.required]],
        childStyle: ['',[Validators.required]]
      })
    ])
    //console.log(this.childrenForm);
    this.childName = new FormControl('', [Validators.required]);
    this.childBirthDay = new FormControl('', [Validators.required]);
    this.childAgeYear = new FormControl('', [Validators.required, Validators.max(18)]);
    this.childAgeMonth = new FormControl('', [Validators.required, Validators.max(36)]);
    this.childGender = new FormControl('', [Validators.required]);
    this.childStyle = new FormControl('', [Validators.required]);
    this.childHeight = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.max(84)]);
    this.childWeight = new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.max(250)]);
    this.shippingName = new FormControl('');
    this.shippingAddressLine1 = new FormControl('',[Validators.required]);
    this.shippingAddressLine2 = new FormControl('');
    this.shippingState = new FormControl('',[Validators.required]);
    this.shippingCity = new FormControl('',[Validators.required,  Validators.minLength(2)]);
    this.shippingZip = new FormControl('',[Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(5),Validators.minLength(5)]);
    this.shippingNote = new FormControl('');
    this.shippingPhone = new FormControl('',[Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10),Validators.minLength(10)]);
    this.shippingDate = new FormControl('',[Validators.required]);
    this.paymentName = new FormControl('');
    this.paymentCCNumber = new FormControl('');
    this.paymentExpDate = new FormControl('');
    this.paymentZip = new FormControl('');
    this.paymentCVC = new FormControl('');
    this.shippingMinDate.setDate(this.todaysDate.getDate()+7);
    this.shippingMaxDate.setDate(this.todaysDate.getDate()+30);
    this.selectChild=0;
    this.cardValidation = false;


  }
  ngAfterViewInit(){
    const cardStyle={
        "base" :{
          "fontFamily": "Maven Pro",
          "fontSize": "20px",
          "color":"gray"
        },
        "invalid":{
          "color":"red"
        }
    }
    this.stripe = Stripe('pk_test_S5lz3xPfdxiP606E0iHU3I45');
    var elements = this.stripe.elements({fonts:[{
      "cssSrc":"https://fonts.googleapis.com/css2?family=Maven+Pro"}]});
    this.card = elements.create('card',{style:cardStyle});
    this.mountCard();
    
  }
  cardValid(){
    this.cardValidation = true;
  }
  mountCard(){
    //console.log("mounted")
    this.cardMount = true;
    this.card.mount('#cardElement');
    this.cardValidation = false;
    //console.log(this.card)
    this.card.addEventListener('change',function(event){
      if(event.complete){
        this.cardValidation = true;
      }
      if(event.error){
        //console.log('not valid')
        this.cardValidation = false;
      }
    }.bind(this));
  }
  async createToken() {
    //console.log("creating token...");
    const {token,error} = await this.stripe.createToken(this.card);
    //console.log(token);
    //console.log(token.id)
    return(token.id)
  }

  async userSubscribe(){
    var tokenId = await this.createToken();
    var childrenInfo=[];
    if(tokenId){
      var shippingDate = this.shippingDate.value.getFullYear()+"-"+(this.shippingDate.value.getMonth()+1)+"-"+this.shippingDate.value.getDate()
      var children=[];
      for (var child of this.childrenForm.value){
        var birthday= child.childBirthday.getFullYear()+"-"+(child.childBirthday.getMonth()+1)+"-"+child.childBirthday.getDate()
        child.childBirthday = birthday
        children.push(child)
      }
      //var subscriptionInfo:LooseObject = {};
      
      var subscriptionInfo = {
        "shippingName":this.shippingName.value,
        "shippingAddressLine1":this.shippingAddressLine1.value,
        "shippingCity":this.shippingCity.value,
        "shippingState":this.shippingState.value,
        "shippingPhone":this.shippingPhone.value,
        "shippingZip":this.shippingZip.value,
        "shippingNotes":this.shippingNote.value,
        "shippingDate":shippingDate,
        "creditToken": tokenId,
        "children":children
      }
      if (this.shippingAddressLine2.value){
        subscriptionInfo["shippingAddressLine2"] = this.shippingAddressLine2.value;
      }
      
      //console.log(subscriptionInfo)
      const subStatus=this.data.subscribe(subscriptionInfo);
      return subStatus
    }else{
      //console.log("error")
      return "error"
    }
    
  }
  async checkSubscription(){
    const userStatus = await this.data.getUsers()
    //var userStatus = "not started";
    if(userStatus == "registered"){
      this.currentStep=4;
    } else{
      this.currentStep=1;
    }
  }
}