import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

//import { userInfo } from 'os';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  asyncResult;
  result;

  constructor( private http: HttpClient, private auth:AuthService) { }
  
  async getUsers() {
    //const token = await this.auth.getTokenSilently$();
    const headers = {
      'Content-Type': 'application/json',
    };
    //console.log(token)
    this.asyncResult = await this.http.get('https://api.evercubby.com/subscribe',{headers}).toPromise();
    //console.log(this.asyncResult);
    return this.asyncResult.status;
  }
  async subscribe(subInfo){
    const headers ={
      'Content-Type':'application/json'
    }
    subInfo = JSON.stringify(subInfo);
    this.asyncResult = await this.http.post('https://api.evercubby.com/subscribe',subInfo,{headers}).toPromise();
  }
  async getSubscriptions(){
    const headers ={
      'Content-Type':'application/json'
    }
    this.asyncResult = await this.http.get('https://api.evercubby.com/subscriptions',{headers}).toPromise();
    console.log(this.asyncResult)
    return this.asyncResult;
  }
  async getPackage(package_id){
    const headers ={
      'Content-Type':'application/json'
    }
    console.log(package_id)
    this.asyncResult = await this.http.get('https://api.evercubby.com/packages/'+package_id,{headers}).toPromise();
    console.log(this.asyncResult)
    return this.asyncResult;
  }
}