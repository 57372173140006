import { Component, OnInit } from '@angular/core';
import { SidnavComponent } from '../sidnav/sidnav.component';
import { SidenavserviceService } from '../sidenavservice.service';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  appTitle: string = 'home'
  navOpen: boolean = false
  constructor(private sidenav : SidenavserviceService, public auth: AuthService ) { }

  toggleActive:boolean = false;

	toggleSidenav() {
    this.toggleActive = !this.toggleActive;
    
    console.log('Clicked');
		this.sidenav.toggle();

  }
  
  ngOnInit() {
    this.navOpen=false
  }

}
