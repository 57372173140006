import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-boxdetails',
  templateUrl: './boxdetails.component.html',
  styleUrls: ['./boxdetails.component.scss']
})
export class BoxdetailsComponent implements OnInit {
  constructor(private route: ActivatedRoute,private data: DataService) { }
  testing: String;
  prepare: boolean;
  shipDeliver: boolean;
  useReturn: boolean;
  packageInfo: any;
  packageInfo2: any;
  deliveryInfo: String;
  deliveryDate: String;
  skipPackageReq: boolean;
  package_id: any;
  childrenSelect: String;

  async ngOnInit() {
    this.package_id = this.route.snapshot.params.id
    if(this.package_id.length > 4){
      this.packageInfo2 = await this.getPackageData(this.package_id)
    }
    console.log(this.packageInfo2)
    this.childrenSelect = 'All'
    this.skipPackageReq = false;
    this.prepare = true;
    this.shipDeliver = true;
    this.useReturn = true;

    this.packageInfo = {
      package_id: "1234",
      status: "At Home",
      statusC: "at_home",
      deliveryDate: "2020-2-24",
      deliveryDay: "Monday",
      deliveryAddress:{
        name:"Sung Lee",
        addressLine1:"123 Park Ln",
        addressLine2: "Apt 13F",
        city:"New York",
        state:"NY",
        zip:19044,
      },
      returnDate: "2020-3-25",
      returnDay: "Tuesday",
      returnedClothing: 12,
      totalClothing: 48,
      children: [{
        birthday: "2017-5-5",
        clothes: 24,
        clothesDetails: [{
          name:'hoodie',
          url: 'assets/img/hoodie.jpg',
          favorite: true,
          return: true
        },
        {
          name:'onesie 1',
          url: 'assets/img/onesie_1.jpg',
          favorite: false,
          return: false
        },
        {
          name:'pants 1',
          url: 'assets/img/pants_1.jpg',
          favorite: false,
          return: false
        },
        {
          name:'top 1',
          url: 'assets/img/top_1.jpg',
          favorite: true,
          return: true
        }],
        gender: "male",
        height: "10",
        name: "Steve Lee",
        returnClothes: 0,
        size: "6M",
        style: "Boy's Casual",
        weight: "10"
      }, {
        birthday: "2020-8-5",
        clothes: 24,
        clothesDetails: [{
          name:'hoodie 2',
          url: 'assets/img/hoodie_2.jpg',
          favorite: true,
          return: true
        },
        {
          name:'top 1',
          url: 'assets/img/top_1.jpg',
          favorite: false,
          return: false
        },
        {
          name:'onesie 2',
          url: 'assets/img/onesie_2.jpg',
          favorite: true,
          return: false
        },
        {
          name:'pants 2',
          url: 'assets/img/pants_2.jpg',
          favorite: false,
          return: false
        }],
        gender: "male",
        height: "10",
        name: "Josh Lee",
        returnClothes: 0,
        size: "Youth S",
        style: "Boy's Casual",
        weight: "10"
      }],
    }
    this.deliveryInfo = JSON.stringify(this.packageInfo.deliveryAddress)
    this.deliveryDate = this.packageInfo.deliveryDate
    this.enrichPackage(this.packageInfo)
    //console.log(this.packageInfo)
  }
  enrichPackage(packageInfo){
    let child: any = {}
    var colors = ['lightblue','lightpink','lightgreen','lightcoral','lightslategray']
    var count = 0;
    let children: any = []
    for (child of packageInfo.children) {
      child.color = colors[count]
      children.push(child)
      count = count + 1
      var bday= new Date(child.birthday)
      var today = new Date()
      var bdayDiff = new Date(today.getTime() -bday.getTime());
      if (Math.abs(bdayDiff.getUTCFullYear() - 1970) >= 2){
        var age = Math.abs(bdayDiff.getUTCFullYear() - 1970).toString()
      }
      else{
        var diffmonth= 0;
        diffmonth = diffmonth + (today.getFullYear() - bday.getFullYear())*12
        if ((today.getMonth() - bday.getMonth()) > 0) {
          diffmonth = diffmonth + (today.getMonth() - bday.getMonth())
        }
        var age = diffmonth+'M'
      }
      child.age=age
      child.obj = JSON.stringify(child);
    }
    this.packageInfo.children = children
    //console.log(children)
  }

  clothesList(children) {
    let child: any = {}
    let childClothes: any = {}
    var clothes = [];
    for (child of children) {
      for (childClothes of child.clothesDetails) {
        var clothesItem = {
          childName: child.name,
          name: childClothes.name,
          url: childClothes.url,
          favorite: childClothes.favorite,
          return: childClothes.return,
          color:child.color
        }
        clothes.push(clothesItem)
      }
    }
    return clothes
  }
  async getPackageData(package_id){
    let child: any = {};
    let clothes: any = {};
    var allClothes= [];
    const packageData = await this.data.getPackage(package_id)
    for (child of packageData.children) {
      for(clothes of child.clothesDetails){
        clothes.filter = true;
        clothes.child = child.name
        allClothes.push(clothes)
      }
    }
    packageData.allClothes = allClothes
    //var userStatus = "not started";
   // console.log(packageData)
    return packageData;
  }

  filterClothes(type){
    console.log(type)
    let clothes: any = {};
    var filterList = [];
    var i =0;
    if(this.packageInfo2.allClothes.length != this.packageInfo2.allClothes.filter(x => x.filter === true).length  || type=='All'){
      console.log('resetting')
      for([i,clothes] of this.packageInfo2.allClothes.entries()){
        this.packageInfo2.allClothes[i].filter = true
      }
    }
    else if (type == 'Favorites'){
      console.log('favs selecting')
      for( [i,clothes] of this.packageInfo2.allClothes.entries()){
        if (clothes.favorite == true){
          this.packageInfo2.allClothes[i].filter = true;
        }
        else{
          this.packageInfo2.allClothes[i].filter = false;
        }
      }
    }
    else{
      console.log('child selection')
      for([i,clothes]  of this.packageInfo2.allClothes.entries()){
        if (clothes.name == type){
          this.packageInfo2.allClothes[i].filter = true;
        }
        else{
          this.packageInfo2.allClothes[i].filter = false;
        }
      }
    }
    console.log(this.packageInfo2)
  }

  favoriteToggle (childName,name){
    console.log(childName)
    console.log(name)
    let child: any ={};
    this.packageInfo2.allClothes.find((o, i) => {
      if (o.name === name && o.child === childName) {
        this.packageInfo2.allClothes[i].favorite = !this.packageInfo2.allClothes[i].favorite
          return true; // stop searching
      }
  });

  }

}
