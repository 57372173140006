import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormArray, Form } from '@angular/forms';


@Component({
  selector: 'app-child-edit',
  templateUrl: './child-edit.component.html',
  styleUrls: ['./child-edit.component.scss']
})
export class ChildEditComponent2 implements OnInit {
  child: any;
  childForm: FormGroup;
  constructor(private route: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.child = JSON.parse(params['data']);
      console.log(this.child)
    });
    console.log(this.child)
    this.childForm = this.fb.group({
      name: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      style: ['', [Validators.required]],
      size: ['', [Validators.required]],
      weight: [{value: '', disabled: true}, [Validators.required]],
      height: [{value: '', disabled: true}, [Validators.required]]
    })
    console.log(this.childForm)
    this.childForm.controls['name'].setValue(this.child.name)
    this.childForm.controls['birthday'].setValue(new Date(this.child.birthday))
    this.childForm.controls['gender'].setValue(this.child.gender)
    this.childForm.controls['size'].setValue(this.child.size)
    this.childForm.controls['style'].setValue(this.child.style)
    this.setWeightandHeight(this.child.size)
  }
  setWeightandHeight(size) {
    var height;
    var weight;
    switch (size) {
      case '1M':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case '2M':
        height = "8-20in"
        weight = "12-20lbs"
        break;
      case '3M':
        height = "6-10in"
        weight = "18-40lbs"
        break;
      case '6M':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case '12M':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'YS':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'YM':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'YL':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'S':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'M':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'L':
        height = "6-10in"
        weight = "10-15lbs"
        break;
      case 'XL':
        height = "6-10in"
        weight = "10-15lbs"
        break;
    }
    this.childForm.controls['height'].setValue(height)
    this.childForm.controls['weight'].setValue(weight)
  }

}
