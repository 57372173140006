import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { NativeDateAdapter } from "@angular/material";
import { FormControl } from '@angular/forms';


export class AppDateAdapter extends NativeDateAdapter {
  months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth()+1;
      const year = date.getFullYear();
      return `${month}/${year}`;
    }
    return date.toDateString();
  }
}

export interface DialogData {
  name: string;
}

export const APP_DATE_FORMATS =
{
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },

    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
  ],
})

export class SettingsComponent {
  name: string;
  date_example = new FormControl(new Date());

  constructor( public dialog: MatDialog) { }
  
  openDialog( card_id: String ) {
    console.log(card_id)
    const dialogRef = this.dialog.open(CardEditComponent, {
      width: '60%',
      data: {card_id: card_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openDialog2( child_id: String) {
    console.log(child_id)
    const dialogRef = this.dialog.open(ChildEditComponent, {
      width: '60%',
      data: {child_id: child_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  chosenMonthHandler($event, datepicker) {
    console.log($event)
    const date_value=new Date($event)
    console.log(date_value.getMonth())
    console.log(date_value.getFullYear())
    this.date_example.setValue(date_value)
  }
  ngOnInit() {
  }

}

@Component({
  selector: 'card_edit',
  templateUrl: 'card_edit.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },

    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
  ],
})

export class CardEditComponent {
  card_id: String;
  date_example = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<CardEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.card_id = data.card_id
      console.log(data)
    }
    ngOnInit() {
      console.log(this.card_id)
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  chosenMonthHandler($event, datepicker) {
    console.log($event)
    const date_value=new Date($event)
    console.log(date_value.getMonth())
    console.log(date_value.getFullYear())
    this.date_example.setValue(date_value)
  }
}

@Component({
  selector: 'children_edit',
  templateUrl: 'children_edit.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },

    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
  ],
})

export class ChildEditComponent {
  child_id: String;
  date_example = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<ChildEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.child_id = data.child_id
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  chosenMonthHandler($event, datepicker) {
    console.log($event)
    const date_value=new Date($event)
    console.log(date_value.getMonth())
    console.log(date_value.getFullYear())
    this.date_example.setValue(date_value)
  }
}