import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
//import { ContactComponent } from './contact/contact.component';
import { BoxdetailsComponent } from './boxdetails/boxdetails.component';
import { BoxdetailsEditComponent } from "./boxdetails-edit/boxdetails-edit.component";
import { ChildEditComponent2 } from "./child-edit/child-edit.component";
import { CallbackComponent } from './callback/callback.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthGuard } from './auth.guard';
import { SignupComponent } from './signup/signup.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'about', component: AboutComponent,canActivate:[AuthGuard] },
  //{ path: 'survey', component: ContactComponent },
  { path: 'details/:id', component: BoxdetailsComponent },
  { path: 'packageEdit/:id', component: BoxdetailsEditComponent },
  { path: 'childEdit', component: ChildEditComponent2 },
  { path:'callback', component:CallbackComponent},
  { path:'settings', component:SettingsComponent },
  { path:'signup', component:SignupComponent,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ]
})
export class AppRoutingModule { }
