import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  users: any;
  user: any;
  userInfo: any;
  subscriptionInfo: any;
  subscriptionInfo2: any;
  subscriptionInfo3: any;
  packageInfo: any;
  constructor(private data: DataService) { }

  async ngOnInit() {
    this.subscriptionInfo3 = await this.getSubscription();
    //this.subscriptionInfo2 = await this.enrichSubscription()
    this.subscriptionInfo = {
      packages: [
        {
          package_id: "1234",
          status: "At Home",
          statusC: "at_home",
          deliveryDate: "2020-2-24",
          deliveryDay: "Monday",
          returnDate: "2020-3-25",
          returnDay: "Tuesday",
          returnedClothing: 12,
          totalClothing: 48,
          children: [{
            name: 'Steve Lee',
            size: '6M'
          }, {
            name: 'John Lee',
            size: 'YS'
          }],
          currentStep: {
            "name": "Use & Return Clothes",
            "steps": [
              {
                "name": "Return Package 1",
                "status": "Complete"
              },
              {
                "name": "Return Package 2",
                "status": "Not Started"
              },
              {
                "name": "Return Package 3",
                "status": "Not Started"
              },
              {
                "name": "Return Package 4",
                "status": "Not Started"
              },
            ]
          },
          lastStep: "Ship & Deliver"
        },
        {
          package_id: "1235",
          status: "Shipping",
          statusC: "shipping",
          deliveryDate: "2020-3-24",
          deliveryDay: "Tuesday",
          returnDate: "2020-4-25",
          returnDay: "Thursday",
          returnedClothing: 0,
          totalClothing: 48,
          children: [{
            name: 'Steve Lee',
            size: '6M'
          }, {
            name: 'John Lee',
            size: 'YS'
          }],
          currentStep: {
            "name": "Ship & Deliver Cubby",
            "steps": [
              {
                "name": "Send cubby from evercubby",
                "status": "Complete"
              },
              {
                "name": "Cubby arrives at home",
                "status": "Not Started"
              }
            ]
          },
          lastStep: "Prepare Cubby"
        },
        {
          package_id: "1236",
          status: "Preparing",
          statusC: "preparing",
          deliveryDate: "2020-4-24",
          deliveryDay: "Wednesday",
          returnDate: "2020-5-25",
          returnDay: "Thursday",
          returnedClothing: 0,
          totalClothing: 48,
          children: [{
            name: 'Steve Lee',
            size: '6M'
          }, {
            name: 'John Lee',
            size: 'YS'
          }],
          currentStep: {
            "name": "Prepare Cubby",
            "steps": [
              {
                "name": "Custom build cubby with clothes",
                "status": "Complete"
              },
              {
                "name": "Prepare cubby for shipping",
                "status": "Not Started"
              },
            ]
          }
        },
        {
          package_id: "1237",
          status: "Not Started",
          statusC: "not_started",
          deliveryDate: "2020-5-24",
          deliveryDay: "Thursday",
          returnDate: "2020-6-25",
          returnDay: "Wednesday",
          returnedClothing: 0,
          totalClothing: 48,
          children: [{
            name: 'Steve Lee',
            size: '6M'
          }, {
            name: 'John Lee',
            size: 'YS'
          }],
          currentStep: {
            "name": "Prepare Cubby",
            "steps": [
              {
                "name": "Custom build cubby with clothes",
                "status": "Not Started"
              },
              {
                "name": "Prepare cubby for shipping",
                "status": "Not Started"
              },
            ]
          },
        },
        {
          package_id: "1238",
          status: "Cancelled",
          statusC: "cancelled",
          deliveryDate: "2020-2-24",
          deliveryDay: "Friday",
          returnDate: "2020-3-23",
          returnDay: "Monday",
          returnedClothing: 0,
          totalClothing: 48,
          children: [{
            name: 'Steve Lee',
            size: '6M'
          }, {
            name: 'John Lee',
            size: 'YS'
          }],
          currentStep: {
            "name": "Use & Return Clothes",
            "steps": [
              {
                "name": "Custom build cubby with clothes",
                "status": "Not Started"
              },
              {
                "name": "Prepare cubby for shipping",
                "status": "Not Started"
              },
            ]
          },
        },
      ]

    }






    this.user = {
      "data": [
        {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }, {
          avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
          first_name: "George",
          id: 1,
          last_name: "Bluth"
        }]
    };
  }

  fontColor(status) {
    let color;
    switch (status) {
      case 'At Home':
        color = 'purple';
        break;
      case 'Preparing':
        color = 'salmon';
        break;
      case 'Shipping':
        color = 'green';
        break;
      case 'Not Started':
        color = 'gray';
        break;
      case 'Cancelled':
        color = '#434343';
        break;
      case 'Complete':
        color = 'blue';
        break;
    }
    let styles = {
      'color': color,
    };
    return styles;
  }

  backgroundColor(status) {
    switch (status) {
      case 'At Home':
        return 'purple';
      case 'Preparing':
        return 'salmon';
      case 'Shipping':
        return 'green';
      case 'Not Started':
        return 'gray';
      case 'Cancelled':
        return '#434343';
      case 'Complete':
        return 'blue';
    }
  }

  iconMatch(step) {
    switch (step) {
      case 'Not Started':
        return 'schedule'
      case 'Preparing':
        return 'input'
      case 'Shipping':
        return 'local_shipping'
      case 'At Home':
        return 'home'
      case 'Complete':
        return 'done_all'
      case 'Cancelled':
        return 'close'
    }
  }
  async getSubscription(){
    const subscriptionData = await this.data.getSubscriptions()
    //var userStatus = "not started";
    console.log(subscriptionData)
    return subscriptionData;
  }

  stepMatch(status){
    switch(status){
      case 'Complete':
        return 'radio_button_checked'
      case 'Complete Last':
        return 'check_circle'
      case 'Not Complete':
        return 'radio_button_unchecked'
    }
  }
  async enrichSubscription() {
    let subpackage: any = {}
    let step: any = {}
    let subStep: any = {}
    var subscriptionData = await this.data.getSubscriptions()
    subscriptionData.enrichPackage =[]
    for (subpackage of subscriptionData.packages) {
      //console.log(subpackage)
          var currentStep
          var lastStepId = 0;
          var returnId=0;
          var nextStepId = 0;
          var lastStep
          let stepStatus = [
            {
              "name": "Prepare Cubby",
              "status": "Not Complete"
            },
            {
              "name": "Ship From Evercubby",
              "status": "Not Complete"
            },
            {
              "name": "Deliver at Home",
              "status": "Not Complete"
            },
            {
              "name": "Return clothes",
              "status": "Not Complete"
            },
          ]
          let nextSteps =['Prepare Cubby', 'Ship Cubby', 'Deliver Cubby','Return Package 1','Return Package 2','Return Package 3', 'Return Package 4', 'Complete']
    
          for (step of subpackage.steps) {
            switch (step.name) {
              case 'Prepare Cubby':
                if (step.status == 'Complete') {
                  stepStatus[0].status = 'Complete'
                  nextStepId = 1
                }
                if (step.status == 'In Progress'){
                  stepStatus[0].status = 'In Progress'
                }
                break
              case 'Ship & Deliver':
                for (subStep of step.subSteps) {
                  switch(subStep.name){
                    case 'Send cubby from evercubby':
                      var index = 1
                      break
                    case 'Cubby arrives at home':
                      var index = 2
                      break
                  }
                  if (subStep.complete) {
                    stepStatus[index].status = 'Complete'
                    if(index > lastStepId){
                      lastStepId = index
                      nextStepId = index + 1
                    }
                    
                  }
                }
                break
              case 'Use & Return':
                if (step.status == 'Complete') {
                  stepStatus[3].status = 'Complete'
                  lastStepId = 3
                  nextStepId = 7
                }
                else{
                  var compCounter = 0
                  for (subStep of step.subSteps){
                    
                    if(subStep.complete){
                      compCounter = compCounter + 1
                    }
                  }
                  console.log(compCounter)
                  if (compCounter > 0){
                    returnId = compCounter
                  }
                }
                if (step.status == 'In Progress'){
                  stepStatus[3].status = 'In Progress'
                }
              break;
            }
          }
          if(lastStepId > 1) {
            if(nextStepId == 7){
    
            }
            else{
              nextStepId = nextStepId + compCounter
            }
          }
          if(lastStepId < 0){

          }
          if(nextStepId > 0 && nextStepId < 2){
            stepStatus[nextStepId].status = 'In Progress'
          }
          subpackage.stepStatus = stepStatus
          subpackage.nextStep = nextSteps[nextStepId]
          //subpackage.lastStep = nextSteps[lastStepId]
          if(nextStepId > 0){
            subpackage.stepStatus[lastStepId].status = 'Complete Last'
          }
          subscriptionData.enrichPackage.push(subpackage)
          //console.log(subpackage)

          let child: any = {}
          let totalClothes=0;
          let returnClothes=0;
          for( child of subpackage.children){
            totalClothes = totalClothes + child.clothes
            returnClothes = returnClothes + child.returnClothes
          }
          for (step of subpackage.stepStatus) {
            if (step.status =='In Progress'){
              switch(step.name){
                case 'Prepare Cubby':
                  subscriptionData.enrichStatus = 'Verify details'
                  break
                case 'Ship From Evercubby':
                  subscriptionData.enrichStatus = 'Track cubby'
                  break
                case 'Deliver at Home':
                  subscriptionData.enrichStatus = 'Enjoy your cubby'
                  break
                case 'Return clothes':
                  subscriptionData.enrichStatus = 'Return '+returnClothes+'/'+totalClothes
                  break
              }
            }
          }
        }
        //console.log(subscriptionData)
        
        return subscriptionData
  }
}
