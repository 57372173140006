import { BrowserModule, makeStateKey } from '@angular/platform-browser';
import { NgModule,  } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AboutComponent } from './about/about.component';
//import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidnavComponent } from './sidnav/sidnav.component';
import { SidenavserviceService } from './sidenavservice.service';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import { FormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { BoxdetailsComponent } from './boxdetails/boxdetails.component';
import createAuth0Client from '@auth0/auth0-spa-js';
import { CallbackComponent } from './callback/callback.component';
import { SettingsComponent, CardEditComponent, ChildEditComponent } from './settings/settings.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import{MatNativeDateModule, } from '@angular/material';
import { SignupComponent } from './signup/signup.component';
import { BoxdetailsEditComponent } from './boxdetails-edit/boxdetails-edit.component';
import { ChildEditComponent2 } from './child-edit/child-edit.component';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    AboutComponent,
//    ContactComponent,
    HomeComponent,
    SidnavComponent,
    BoxdetailsComponent,
    CallbackComponent,
    SettingsComponent,
    CardEditComponent,
    ChildEditComponent,
    SignupComponent,
    BoxdetailsEditComponent,
    ChildEditComponent2
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatCardModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatListModule,
    MatIconModule,
    MatGridListModule,
    MatExpansionModule,
    MatSidenavModule,
    MatChipsModule,
    MatBadgeModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  providers: [SidenavserviceService],
  bootstrap: [AppComponent],
  entryComponents: [CardEditComponent, ChildEditComponent]
})
export class AppModule { }
