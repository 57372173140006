import { Component, OnInit,ViewChild } from '@angular/core';
import { SidenavserviceService } from '../sidenavservice.service';
import { MatSidenav } from '@angular/material';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sidnav',
  templateUrl: './sidnav.component.html',
  styleUrls: ['./sidnav.component.scss']
})
export class SidnavComponent implements OnInit {
  @ViewChild('sidenav',{static: false}) public sidenav: MatSidenav;
  constructor(private sidenavService: SidenavserviceService, public auth: AuthService) { }
  ngOnInit(): void {
		this.sidenavService.setSidenav(this.sidenav);
	}

}
